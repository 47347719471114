<template>
    <div class="edu-frame">
        <edu-nav-bar title="活动简介" :show="show" @showPopup="show=true" @hidePopup="show=false"></edu-nav-bar>
        <div class="content-frame">
			<div v-for="(d, index) in activty.details" :key="index" class="edu-group class-item">
				<div v-if="d.itemType=='单选'" class="class-detail">
					<div class="class-detail-row-title">{{d.itemName}}</div>
					<van-radio-group v-model="d.value">
					  <van-radio v-for="(i, inx) in d.items" :key="inx" :name="i.activitySettingItemDetailID">{{i.itemName}}</van-radio>
					</van-radio-group>
				</div>
				<div v-if="d.itemType=='多选'" class="class-detail">
					<div class="class-detail-row-title">{{d.itemName}}</div>
					<van-checkbox-group v-model="d.value">
					  <van-checkbox v-for="(i, inx) in d.items" :key="inx" :name="i.activitySettingItemDetailID">{{i.itemName}}</van-checkbox>
					</van-checkbox-group>
				</div>
				<div v-if="d.itemType=='开关'" class="class-detail">
					<div class="class-detail-row-title">{{d.itemName}}<van-switch v-model="d.value" size="small"/></div>
				</div>
				<div v-if="d.itemType=='单选输入'" class="class-detail">
					<van-field v-model="d.value" :label="d.itemName" />
				</div>
				<div v-if="d.itemType=='商品'" class="class-detail">
					<div class="class-detail-row-title">{{d.itemName}}</div>
					<van-checkbox-group v-model="d.value">
					  <van-checkbox v-for="(i, inx) in d.items" :key="inx" :name="i.activitySettingItemDetailID" label-disabled>
						<div>{{i.itemName}}</div>
						<div>{{i.itemDesc}}</div>
						<div>￥{{i.price}}<van-stepper v-model="i.num" :min="i.minQty" :max="i.maxQty"/></div>
					  </van-checkbox>
					</van-checkbox-group>
				</div>
			</div>
		</div>
    </div>
</template>
<script>
    import { Field, Checkbox, CheckboxGroup, Switch, Dialog, RadioGroup, Radio, Stepper} from "vant";
    import EduNavBar from "@/components/EduNavBar";
    import Tools from "@/api/Tools";
    import XEUtils from 'xe-utils';
	import ActivityApi from "@/api/ActivityApi";

    export default {
        components: {
            EduNavBar,
            VanField: Field,
			VanCheckboxGroup: CheckboxGroup,
            VanCheckbox: Checkbox,
			VanSwitch: Switch,
			VanRadioGroup: RadioGroup,
			VanRadio: Radio,
			VanStepper: Stepper,
			[Dialog.Component.name]: Dialog.Component,
        },
        filters: {
            filterDate(date) {
                return XEUtils.toDateString(date, 'yyyy-MM-dd HH:mm:ss')
            }
        },
        data() {
            return {
				show: false,
				submitting: false,
				cust: Tools.getCurCust(),
				activty:{},
				activitySettingMasterID:this.$route.query.activitySettingMasterID,
				radio: '1',
            }
        },
        methods: {
			getById(){
				this.loading = true;
				ActivityApi.getById(this.activitySettingMasterID).then(response => {
				    this.loading = false
				    this.activty = response.res;
					if(null != this.activty && null != this.activty.details && this.activty.details.length > 0){
						for(let i = 0;i<this.activty.details.length;i++){
							if(this.activty.details[i].itemType=='单选' || this.activty.details[i].itemType=='单选输入'){
								this.$set(this.activty.details[i], 'value','');
							}else if(this.activty.details[i].itemType=='多选'){
								this.$set(this.activty.details[i], 'value',[]);
							}else if(this.activty.details[i].itemType=='开关'){
								this.$set(this.activty.details[i], 'value',false);
							}else if(this.activty.details[i].itemType=='商品'){
								this.$set(this.activty.details[i], 'value',[]);
								for(let j=0;j<this.activty.details[i].items.length;j++){
									this.$set(this.activty.details[i].items[j], 'num',this.activty.details[i].items[j].minQty);
								}
							}
						}
					}
				}).catch(()=>{
				    this.loading = false
				})
			},
			topay(){

			},
        },
        mounted() {
			this.getById();
        }
    }
</script>
<style scoped>
</style>
